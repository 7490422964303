import React from "react";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import ActionDropdown from "../../components/ActionDropdown";
import ProductActionDropdown from "../../components/ProductActionDropdown";
import CouponActionDropdown from "../../components/CouponActionDropdown";

const EcommerceOrderColumns = (getCoupons, setCoupon, setViewModal) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "coupon_name",
    text: "Coupon Code",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.coupon_name}
      </Link>
    ),
  },
  {
    dataField: "min_order_amount",
    text: "Min. Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.min_order_amount}
      </Link>
    ),
  },
  {
    dataField: "max_order_amount",
    text: "Max. Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.max_order_amount}
      </Link>
    ),
  },
  {
    dataField: "min_order_quantity",
    text: "Min. Quantity",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.min_order_quantity}
      </Link>
    ),
  },
  {
    dataField: "user_usage_count",
    text: "User Limit",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.user_usage_count}
      </Link>
    ),
  },
  {
    dataField: "expiration_date",
    text: "Exp. Date",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {/* {row.expiration_date.split("T")[0]} */}
        {new Date(row?.expiration_date).toLocaleDateString("en-GB")}
      </Link>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.type === "PERCENTAGE" ? `${row.amount}%` : `$${row.amount}`}
      </Link>
    ),
  },

  {
    dataField: "type",
    text: "Coupon Type",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.type === "PERCENTAGE"
            ? "success"
            : row.type === "ABSOLUTE"
            ? "warning"
            : "danger")
        }
        color={
          row.type === "PERCENTAGE"
            ? "success"
            : row.type === "ABSOLUTE"
            ? "warning"
            : "danger"
        }
        pill
      >
        {/* {row.type === "PERCENTAGE" ? "%" : "$"} */}
        {row.type}
      </Badge>
    ),
  },
  {
    dataField: "type",
    text: "Module",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={
          "font-size-12 badge-soft-" +
          (row.forShipment === true
            ? "success"
            : row.type === false
            ? "warning"
            : "danger")
        }
        color={
          row.forShipment === "SHIPMENT"
            ? "success"
            : row.forShipment === "MARKETPLACE"
            ? "warning"
            : "danger"
        }
        pill
      >
        {row.forShipment ? "SHIPMENT" : "MARKETPLACE"}
      </Badge>
    ),
  },
  // {
  //   dataField: "expiration_date",
  //   text: "Expiration On date here",
  //   sort: true,
  //   formatter: (cellContent, row) => (
  //     <Link to="#" className="text-body fw-bold">
  //       {new Date(row.expiration_date).toLocaleDateString("en-IN", {
  //         year: "numeric",
  //         month: "long",
  //         day: "numeric",
  //       })}
  //     </Link>
  //   ),
  // },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cellContent, row) => (
      <CouponActionDropdown
        row={row}
        getCoupons={getCoupons}
        setCoupon={setCoupon}
        setViewModal={setViewModal}
        isAdmin={true}
      />
    ),
  },
];

export default EcommerceOrderColumns;
