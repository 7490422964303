import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { del } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Spinner,
  Tooltip,
} from "reactstrap";
function ShippingCalculatorDropdown({
  row,
  setDataObject,
  handleModalShow,
  getAllCategories,
  deleteType,
  isDuration,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
      await del(`/api/shipping-calculator/delete/${row?.id}`, {});
      toastr.success("Deleted", "Success");
      getAllCategories();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
   const [viewModalDelete, setViewModalDelete] = useState(false);
   const handleAddCouponModal = () => {
     setViewModalDelete((prev) => !prev);
   };
  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        color="#000"
        className="shipping-calc"
      >
        <DropdownToggle className="bg-primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              setDataObject(row);
              handleModalShow();
            }}
          >
            <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
          </DropdownItem>
          <DropdownItem
            // onClick={() => {
            //   handleItemClick("DELETE");
            // }}
            onClick={handleAddCouponModal}
          >
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal size="md" isOpen={viewModalDelete} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {/* {coupon?.id ? "Edit" : "New"} */}
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription3">
                  Are you sure you want to delete this item?
                </Label>
              </FormGroup>
            </Col>
          </Row>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
              gap: "20px",
            }}
          >
            <Button
              size="md"
              onClick={() => handleItemClick("DELETE", row.id)}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              size="md"
              onClick={handleAddCouponModal}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ShippingCalculatorDropdown;
