import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import toastr from "toastr";
import Editor from "react-simple-wysiwyg";
import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";

const PromotionalNotification = () => {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const [notifcationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const [notification, setNotification] = useState([]);
  const pageOptions = {
    sizePerPage: 20,
    totalSize: notification.length,
    custom: true,
  };
  const { SearchBar } = Search;
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  function showNewModal() {
    setShowModal((prev) => !prev);
    setNotificationTitle("");
    setNotificationDescription("");
  }

  // const selectRow = {
  //   mode: "checkbox",
  // };
  async function getAllNotifications() {
    const notificationInfo = await get("/api/get-promotional-notification", {});
    setNotification(notificationInfo);
  }

  async function createNewnotification() {
    try {
      setLoading(true);
      if (!notifcationTitle) {
        toastr.error("Please provide title");
        setLoading(false);
        return;
      }
      if(notifcationTitle.length > 65){
        toastr.error("Title max length is 65 character");
        setLoading(false);
        return
      }
      
      if (!notificationDescription) {
        toastr.error("Please provide description");
        setLoading(false);
        return;
      }
      if(notificationDescription.length > 240){
        toastr.error("Description max length is 240 character");
        setLoading(false);
        return;
      }
      let title = notifcationTitle;
      let description = notificationDescription;
      let body = { title, description };
      let res = await post("/api/create-promotional-notification", body);
      if (!res) {
        toastr.error(res.message);
        setLoading(false);
        return;
      }
      toastr.success(res.message);
      getAllNotifications();
      showNewModal();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  const variables = [
    { label: "First Name", value: "{{first_name}}" },
    { label: "Last Name", value: "{{last_name}}" }
  ];
  const [variableFocus,setVariableFocus] = useState("title")
  const handleFocus = (focas) =>{
    setVariableFocus(focas);
    
  }
  const handleVariableSelect = (event) => {
    const selectedVariable = event.target.value;
    if (selectedVariable) {
      if(variableFocus === "title"){
        setNotificationTitle((prev) => (prev + " " + selectedVariable));
      }else{
        setNotificationDescription((prev)=>(prev + " "+ selectedVariable))
      }
     
    }
  };
  useEffect(() => {
    getAllNotifications();
  }, [user]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Promotional Notification"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Promotional Notification", link: "/#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={notification}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={notification}
                        columns={EcommerceOrderColumns(
                          dispatch,
                          navigation,
                          user
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showNewModal();
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Create
                                    New Notification
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check custom-scroll-table"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          Create New Notification
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="title">
                  Title
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  value={notifcationTitle || ""}
                  onChange={(e) => setNotificationTitle(e.target.value)}
                  onFocus={() => handleFocus("title")}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
            <Label className="form-label" htmlFor="productdesc">
              Description
            </Label>
            <Editor
              containerProps={{ style: { height: "200px" } }}
              value={notificationDescription || ""}
              onChange={(e) => setNotificationDescription(e.target.value)}
              onFocus={() => handleFocus("description")}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="variable-select">Insert Variable:</Label>
            <Input
              type="select"
              id="variable-select"
              onChange={handleVariableSelect}
            >
              <option value="">Select a variable</option>
              {variables.map((variable, index) => (
                <option key={index} value={variable.value}>
                  {variable.label}
                </option>
              ))}
            </Input>
          </div>
          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner style={{ width: "2rem", height: "2rem" }} />
                ) : (
                  <Button
                    color="primary"
                    onClick={createNewnotification}
                    className="w-md waves-effect waves-light"
                  >
                    Send
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default PromotionalNotification;
