// import React, { useEffect, useState } from "react";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import { withNamespaces } from "react-i18next";
// import avatar2 from "../../../assets/images/user-icon-header.png";
// import { useDispatch, useSelector } from "react-redux";
// import { get, post } from "../../../helpers/api_helper";
// import {
//   addUserInfo,
//   removeUserInfo,
// } from "../../../store/userAuthInfo/actions";
// import { useHistory } from "react-router-dom";
// import { vendor_change_password } from "../../../store/layout/actions";

// const ProfileMenu = ({ t }) => {
//   const [menu, setMenu] = useState(false);
//   const user = useSelector((state) => state.userAuthInfo.user);
//   const dispatch = useDispatch();
//   const navigation = useHistory();
//   const toggle = () => {
//     setMenu((prevMenu) => !prevMenu);
//   };
//   async function getUserInfo() {
//     const token = JSON.parse(localStorage.getItem("auth_info"));
//     if (token) {
//       try {
//         const content = await post("/api/auth/info");
//         dispatch(addUserInfo(content));
//       } catch (e) {
//         localStorage.clear();
//         dispatch(removeUserInfo());
//         navigation.push("/login");
//         console.log(e);
//       }
//     }
//   }

//   useEffect(() => {
//     getUserInfo();
//   }, []);
//   return (
//     <React.Fragment>
//       <Dropdown
//         isOpen={menu}
//         toggle={toggle}
//         className="d-inline-block user-dropdown"
//       >
//         <DropdownToggle
//           tag="button"
//           className="btn header-item waves-effect"
//           id="page-header-user-dropdown"
//         >
//           <img
//             className="rounded-circle header-profile-user me-1"
//             src={avatar2}
//             alt="Header Avatar"
//           />
//           <span className="d-none d-xl-inline-block ms-1 text-transform">
//             {user?.username}
//           </span>
//           <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
//         </DropdownToggle>
//         <DropdownMenu className="dropdown-menu-end">
//           {/* <DropdownItem href="#">
//             <i className="ri-user-line align-middle me-1"></i> {t("Profile")}
//           </DropdownItem>
//           <DropdownItem href="#">
//             <i className="ri-wallet-2-line align-middle me-1"></i>{" "}
//             {t("My Wallet")}
//           </DropdownItem>
//           <DropdownItem className="d-block" href="#">
//             <span className="badge badge-success float-end mt-1">11</span>
//             <i className="ri-settings-2-line align-middle me-1"></i>{" "}
//             {t("Settings")}
//           </DropdownItem>
//           <DropdownItem href="#">
//             <i className="ri-lock-unlock-line align-middle me-1"></i>{" "}
//             {t("Lock screen")}
//           </DropdownItem>
//           <DropdownItem divider />
//           */}
//           <DropdownItem
//             className="text-danger"
//             onClick={() => {
//               localStorage.clear();
//               dispatch(removeUserInfo());
//               navigation.push("/login");
//             }}
//           >
//             <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
//             {t("Logout")}
//           </DropdownItem>
//           <DropdownItem
//             className="text-danger"
//             onClick={() => {
//               dispatch(vendor_change_password(true));
//               // navigation.push("")
//               // localStorage.clear();
//               // dispatch(removeUserInfo());
//               // navigation.push("/login");
//             }}
//           >
//             <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
//             {t("Change Password")}
//           </DropdownItem>
//         </DropdownMenu>
//       </Dropdown>
//     </React.Fragment>
//   );
// };

// export default withNamespaces()(ProfileMenu);

import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import avatar2 from "../../../assets/images/user-icon-header.png";
import { useDispatch, useSelector } from "react-redux";
import { post } from "../../../helpers/api_helper";
import toastr from "toastr";
import {
  addUserInfo,
  removeUserInfo,
} from "../../../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
import { vendor_change_password } from "../../../store/layout/actions";

const ProfileMenu = ({ t }) => {
  const [menu, setMenu] = useState(false);
  const [modal, setModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useSelector((state) => state.userAuthInfo.user);
  const dispatch = useDispatch();
  const navigation = useHistory();

  const toggleMenu = () => setMenu((prevMenu) => !prevMenu);
  const toggleModal = () => setModal((prevModal) => !prevModal);

  const token = JSON.parse(localStorage.getItem("auth_info"));
  async function getUserInfo() {

    if (token) {
      try {
        const content = await post("/api/auth/info");
        dispatch(addUserInfo(content));
      } catch (e) {
        localStorage.clear();
        dispatch(removeUserInfo());
        navigation.push("/login");
        console.log(e);
      }
    }
  }

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toastr.error("new password and confirm password is not match")
      return;
    }

    const passwordRegex =
      /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/;

    if (!passwordRegex.test(newPassword)) {
      toastr.error("please enter right password");
      return;
    }
    try {
      let body = {
        vendor_id: token.id,
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      };
      let responce = await post("/api/vendor-password-change", body);

      toastr.success(responce.message);
      toggleModal();
    } catch (e) {
      console.log(e|| "An error occurred");
      toastr.error(e.response?.data?.message);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggleMenu}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user me-1"
            src={avatar2}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 text-transform">
            {user?.username}
          </span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {token?.isAdmin === false && (
            <DropdownItem
              className="text-danger"
              onClick={() => {
                toggleModal();
              }}
            >
              <i className="ri-lock-line align-middle me-1 text-danger"></i>{" "}
              {t("Change Password")}
            </DropdownItem>
          )}
          <DropdownItem
            className="text-danger"
            onClick={() => {
              localStorage.clear();
              dispatch(removeUserInfo());
              navigation.push("/login");
            }}
          >
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t("Change Password")}</ModalHeader>
        <ModalBody>
          <Input
            type="password"
            placeholder={t("Old Password")}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="mb-3"
          />
          <Input
            type="password"
            placeholder={t("New Password")}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="mb-3"
          />
          <Input
            type="password"
            placeholder={t("Confirm Password")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleChangePassword}>
            {t("Submit")}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
