import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Spinner,
  Tooltip,
} from "reactstrap";

import { post, put } from "../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import {
  selectCategoryToUpdate,
  selectProductToUpdate,
} from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";
import "./common.modules.css"
function CouponActionDropdown({ row, getCoupons,setCoupon,setViewModal,isAdmin=false }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = () => {
    setDropdownOpen(false);
  };
  const handleDeleteClick = () =>{
      setViewModalDelete((prev) => !prev);

  }
  async function deleteCoupon() {
    try {
      if(isAdmin){
        await post(`/api/coupon-admin-delete`, {
          couponId: row.id,
        });
      }else{
        await post(`/api/coupon-delete`, {
          couponId: row.id,
        });
      }
      toastr.success("Status updated!", "Success");
      getCoupons();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  async function getCouponByName() {
    try {
     const coupon =  await post(`/api/get-coupon-by-name`, {
        coupon_name: row.coupon_name,
      });
      const updatedCoupon = {...coupon,expiration_date:new Date(coupon.expiration_date),amount:parseFloat(coupon.amount),min_order_amount:parseFloat(coupon.min_order_amount)}
      setCoupon(updatedCoupon)
      setViewModal(true)
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
    const [viewModalDelete, setViewModalDelete] = useState(false);
    const handleAddCouponModal = () => {
      setViewModalDelete((prev) => !prev);
    };
  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        color="#a8d1df"
        className="coupon-dropdwon"
      >
        <DropdownToggle caret style={{ backgroundColor: "#00b4d8" }}>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>

        <DropdownMenu
          style={{ position: "absolute", left: "-100px !important" }}
        >
          <DropdownItem
            onClick={() => {
              getCouponByName();
            }}
            style={{ left: "100px" }}
          >
            <i className="mdi mdi-clipboard-edit font-size-18 me-2"></i>EDIT
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              // deleteCoupon();
              handleDeleteClick();
            }}
          >
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal size="md" isOpen={viewModalDelete} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {/* {coupon?.id ? "Edit" : "New"} */}
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription3">
                  Are you sure you want to delete this item?
                </Label>
              </FormGroup>
            </Col>
          </Row>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
              gap: "20px",
            }}
          >
            <Button
              size="md"
              onClick={deleteCoupon}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              size="md"
              onClick={handleAddCouponModal}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default CouponActionDropdown;
