import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { post, put } from "../../helpers/api_helper";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ProductManagement.css"
function ActionDropdown({
  isEcom,
  row,
  getAllVendorRequests,
  categories,
}) {
  console.log("order row",row);
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const navigation = useHistory();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    updateVendorRequestStatus(item);
  };
  async function updateVendorRequestStatus(status) {
    try {
        let id = row.id
      await post(`/api/update-product-status`, {
        id,
        isActive: status,
      });
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df" className="product_manage">
      <DropdownToggle className="bg-primary" caret>
        <i className="mdi mdi-menu font-size-18"></i>
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem onClick={() => handleItemClick(true)}>
          <i className="mdi mdi-clock font-size-18 me-2"></i>ACTIVE
        </DropdownItem>
        <DropdownItem onClick={() => handleItemClick(false)}>
          <i className="mdi mdi-eye-off font-size-18 me-2"></i>HIDE
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default ActionDropdown;
