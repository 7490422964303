// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap";
// import { put } from "../helpers/api_helper";
// import toastr from "toastr";
// import { useDispatch } from "react-redux";
// import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
// import { useHistory } from "react-router-dom";
// function SubCategoryActionDropdown({isEcom, row, getAllVendorRequests,categories }) {
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const dispatch = useDispatch()
//   const navigation = useHistory()
//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     setDropdownOpen(false);
//     updateVendorRequestStatus(item);
//   };
//   async function updateVendorRequestStatus(status) {
//     try {
//       const url= isEcom?'ecom/':''
//       await put(`/api/${url}category/${row.id}`, {
//         isActive: status,
//         isSubCategory:true
//       });
//       toastr.success("Status updated!", "Success");
//       getAllVendorRequests();
//     } catch (e) {
//       toastr.error("Something went wrong", "Error");
//       console.log(e);
//     }
//   }
//   return (
//     <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
//       <DropdownToggle className="bg-primary" caret>
//         <i className="mdi mdi-menu font-size-18"></i>
//       </DropdownToggle>

//       <DropdownMenu>
//         <DropdownItem
//           onClick={() => {
//             dispatch(selectCategoryToUpdate({ row, categories }));
//             navigation.push("/add-new-sub-category");
//           }}
//         >
//           <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
//         </DropdownItem>
//         <DropdownItem
//           onClick={() => {
//             handleItemClick("DELETE");
//           }}
//         >
//           <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
//         </DropdownItem>
//       </DropdownMenu>
//     </Dropdown>
//   );
// }

// export default SubCategoryActionDropdown;



import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { put, del } from "../helpers/api_helper"; // Assuming DELETE API call
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { selectCategoryToUpdate } from "../store/userAuthInfo/actions";
import { useHistory } from "react-router-dom";

function SubCategoryActionDropdown({
  isEcom,
  row,
  getAllVendorRequests,
  categories,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigation = useHistory();

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
  const handleDeleteClick = () =>{
    updateVendorRequestStatus("DELETE")
  }
  async function updateVendorRequestStatus(status) {
    try {
      const url = isEcom ? "ecom/" : "";
      await put(`/api/${url}category/${row.id}`, {
        isActive: status,
        isSubCategory: true,
      });
      toastr.success("Status updated!", "Success");
      getAllVendorRequests();
    } catch (e) {
      toastr.error("Something went wrong", "Error");
      console.log(e);
    }
  }

  async function deleteCategory() {
    try {
      const url = isEcom ? "ecom/" : "";
      await del(`/api/${url}category/${row.id}`); // Assuming DELETE API
      toastr.success("Category deleted successfully!", "Success");
      getAllVendorRequests();
      toggleDeleteModal();
    } catch (e) {
      toastr.error("Failed to delete category", "Error");
      console.log(e);
    }
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle className="bg-primary" caret>
          <i className="mdi mdi-menu font-size-18"></i>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              dispatch(selectCategoryToUpdate({ row, categories }));
              navigation.push("/add-new-sub-category");
            }}
          >
            <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
          </DropdownItem>
          <DropdownItem onClick={toggleDeleteModal}>
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      {/* DELETE CONFIRMATION MODAL */}
      <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this item?</ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
              gap: "20px",
            }}
          >
            <Button
              size="md"
              onClick={handleDeleteClick}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              size="md"
              onClick={toggleDeleteModal}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SubCategoryActionDropdown;
