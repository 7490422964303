import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Spinner,
  Tooltip,
} from "reactstrap";
import { del, post, put } from "../helpers/api_helper";
import toastr from "toastr";
function EmailTemplateActionDropdown({
  getEmailTemplates,
  row,
  toggleModal,
  setEmailTemplate,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  async function deleteEmailTemplate() {
    try {
      await post("/api/email-template-delete", { id: row.id });
      toastr.success("Success!");
      getEmailTemplates();
    } catch (e) {
      toastr.error(e?.response?.data?.message, "Error");
      console.log(e);
    }
  }
    const [viewModalDelete, setViewModalDelete] = useState(false);
     const handleAddCouponModal = () => {
       setViewModalDelete((prev) => !prev);
     };
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#a8d1df">
        <DropdownToggle color="primary" className="primary" caret>
          Actions
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              setDropdownOpen(false);
              setEmailTemplate(row);
              toggleModal();
            }}
          >
            <i className="mdi mdi-pencil font-size-18 me-2"></i>EDIT
          </DropdownItem>
          <DropdownItem
            onClick={handleAddCouponModal}
            // onClick={() => {
            //   setDropdownOpen(false);
            //   deleteEmailTemplate();
            // }}
          >
            <i className="mdi mdi-delete font-size-18 me-2"></i>DELETE
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal size="md" isOpen={viewModalDelete} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {/* {coupon?.id ? "Edit" : "New"} */}
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription3">
                  Are you sure you want to delete this item?
                </Label>
              </FormGroup>
            </Col>
          </Row>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
              gap: "20px",
            }}
          >
            <Button
              size="md"
              onClick={() => deleteEmailTemplate()}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              size="md"
              onClick={handleAddCouponModal}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default EmailTemplateActionDropdown;
