import React, { useEffect, useState } from "react";
import {useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {PaginationProvider,PaginationListStandalone} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button,Card,CardBody,Col,Container,Row,Modal,ModalHeader,ModalBody,Label,FormGroup,Input,Spinner,Tooltip} from "reactstrap";
import Switch from "react-switch";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post } from "../../helpers/api_helper";
import toastr from "toastr";
// import "./TableResponsive.css"

const AdminCoupons = () => {
  const [viewModal, setViewModal] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    amount: "",
    type: "",
    min_order_amount: "",
    expiration_date: "",
    coupon_name: "",
    coupon_description: "",
    max_order_amount: "",
    user_usage_count: "",
    min_order_quantity: "",
    isPublic: false,
    forShipment: false,
    isLimited: false,
    max_redemptions: 0,
    usersendMail: false,
    discription1: "",
    discription2: "",
    discription3: "",
    discription4: "",
  });
  const [tooltipOpen, setTooltipOpen] = useState({
    amount: false,
    type: false,
    min_order_amount: false,
    expiration_date: false,
    coupon_name: false,
    coupon_description: false,
    max_order_amount: false,
    user_usage_count: false,
    min_order_quantity: false,
    isPublic: false,
    forShipment: false,
    isLimited: false,
    max_redemptions: false,
    usersendMail: false,
    discription1: false,
    discription2: false,
    discription3: false,
    discription4: false,
  });
   const toggleTooltip = (tooltip) => {
     setTooltipOpen((prevState) => ({
       ...prevState,
       [tooltip]: !prevState[tooltip],
     }));
   };
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const handleAddCouponModal = () => {
    setViewModal((prev) => !prev);
    setCoupon(null);
  };
  function Offsymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        No
      </div>
    );
  }
  function OnSymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        Yes
      </div>
    );
  }
  function CouponOffsymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        $
      </div>
    );
  }
  function CouponOnSymbol() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        %
      </div>
    );
  }
  useEffect(() => {
    getCoupons();
  }, [user]);
  async function getCoupons() {
    try {
      const content = await post("/api/get-admin-coupons", {
        vendor_id: user?.id,
      });
      setCoupons(content);
    } catch (e) {
      console.log(e);
    }
  }
  const { SearchBar } = Search;
  // Pagination customization
  const pageOptions = {
    sizePerPage: 20,
    totalSize: coupons.length,
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];
  // const selectRow = {
  //   mode: "checkbox",
  // };
      console.log("coupon final data", coupon);

  async function submitRequest() {
    try {
      //  if (!coupon?.forShipment && !coupon?.forMarketplace) {
      //    toastr.error("You must select either 'For Shipment' or 'For Marketplace'.");
      //    return;
      //  }
      console.log("coupon final data",coupon);
      
      setLoading(true);
      const content = await post("/api/create-admin-coupon", {
        ...coupon,
      });
      setLoading(false);
      getCoupons();
      handleAddCouponModal();
      toastr.success("Request created successfully!");
    } catch (e) {
      toastr.error(e?.response?.data?.error, "Error");
      setLoading(false);
      console.log(e);
    }
  }
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Coupon Management"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Coupon Management", link: "/coupons" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={coupons}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={coupons}
                        columns={EcommerceOrderColumns(
                          getCoupons,
                          setCoupon,
                          setViewModal
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddCouponModal}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Create
                                    New Coupon
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ height: "500px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check custom-scroll-table"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="md" isOpen={viewModal} toggle={handleAddCouponModal}>
        <ModalHeader toggle={handleAddCouponModal}>
          {coupon?.id ? "Edit" : "New"} Coupon Form
        </ModalHeader>
        <ModalBody>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponcode">Coupon Code</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="coupon_name"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("coupon_name")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.coupon_name}
                  target="coupon_name"
                  toggle={() => toggleTooltip("coupon_name")}
                >
                  Unique code customers can apply to redeem the coupon.
                </Tooltip>
                <Input
                  type="text"
                  id="couponcode"
                  placeholder="Enter Coupon Code"
                  value={coupon?.coupon_name}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\s/g, ""); // Remove all spaces
                    setCoupon((prev) => ({
                      ...prev,
                      coupon_name: value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponcode">Coupon Summary</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="coupon_description"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("coupon_description")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.coupon_description}
                  target="coupon_description"
                  toggle={() => toggleTooltip("coupon_description")}
                >
                  A brief description or summary of the coupon’s purpose and
                  benefits.
                </Tooltip>
                <Input
                  type="text"
                  id="coupondes"
                  placeholder="Eg: Get 20% off on your next purchase!"
                  value={coupon?.coupon_description}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      coupon_description: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minordervalue">Minimum Order Value</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="min_order_amount"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("min_order_amount")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.min_order_amount}
                  target="min_order_amount"
                  toggle={() => toggleTooltip("min_order_amount")}
                >
                  The minimum cart value required to apply the coupon.
                </Tooltip>
                <Input
                  type="string"
                  id="minordervalue"
                  placeholder="Enter Minimum Order Value"
                  value={
                    parseInt(
                      coupon?.min_order_amount
                        ?.toString()
                        ?.replace(/[^0-9.]/g, "")
                    ) || 0
                  }
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_amount: parseInt(e.target.value),
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="maxordervalue">Maximum Order Value</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="max_order_amount"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("max_order_amount")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.max_order_amount}
                  target="max_order_amount"
                  toggle={() => toggleTooltip("max_order_amount")}
                >
                  The maximum cart value eligible for the coupon.
                </Tooltip>
                <Input
                  type="string"
                  id="maxordervalue"
                  placeholder="Enter Maximum Order Value"
                  value={coupon?.max_order_amount}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      max_order_amount:
                        parseInt(
                          e.target.value.toString()?.replace(/[^0-9.]/g, "")
                        ) || 0,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="minorderquantity">Minimum Order Quantity</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="min_order_quantity"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("min_order_quantity")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.min_order_quantity}
                  target="min_order_quantity"
                  toggle={() => toggleTooltip("min_order_quantity")}
                >
                  The minimum quantity of items required to use the coupon.
                </Tooltip>
                <Input
                  type="string"
                  id="minorderquantity"
                  placeholder="Enter Minimum Order Quantity"
                  value={coupon?.min_order_quantity}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      min_order_quantity:
                        parseInt(
                          e.target.value.toString()?.replace(/[^0-9]/g, "")
                        ) || 0,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="usrusagecount">User Usage Limit</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="user_usage_count"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("user_usage_count")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.user_usage_count}
                  target="user_usage_count"
                  toggle={() => toggleTooltip("user_usage_count")}
                >
                  The maximum number of times a single user can use the coupon.
                </Tooltip>
                <Input
                  type="string"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  id="usrusagecount"
                  placeholder="Enter User Usage Count"
                  value={coupon?.user_usage_count}
                  onChange={(e) => {
                    const value = parseInt(
                      e.target.value.toString()?.replace(/[^0-9.]/g, 0)
                    );

                    setCoupon((prev) => ({
                      ...prev,
                      user_usage_count: !isNaN(value) ? value : "",
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="expiry">Expiry Date</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="expiration_date"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("expiration_date")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.expiration_date}
                  target="expiration_date"
                  toggle={() => toggleTooltip("expiration_date")}
                >
                  The date when the coupon will no longer be valid.
                </Tooltip>
                <DatePicker
                  selected={coupon?.expiration_date}
                  onChange={(date) => {
                    setCoupon((prev) => ({
                      ...prev,
                      expiration_date: date,
                    }));
                  }}
                  minDate={tomorrow}
                  placeholderText="Select expiration date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <Label for="toogle-percentage">Discount Type</Label>

              <i
                className="mdi mdi-information-outline font-size-18"
                id="type"
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => toggleTooltip("type")}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.type}
                target="type"
                toggle={() => toggleTooltip("type")}
              >
                Select whether the discount is a fixed amount or a percentage.
                By default, it's a fixed amount.
              </Tooltip>
              <FormGroup>
                <Switch
                  id="toogle-percentage"
                  uncheckedIcon={<CouponOffsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<CouponOnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({ ...prev, type: !coupon?.type }))
                  }
                  checked={coupon?.type}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="couponamount">Discount Value</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="amount"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("amount")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.amount}
                  target="amount"
                  toggle={() => toggleTooltip("amount")}
                >
                  The value of the discount in either amount or percentage,
                  depending on the selected discount type.
                </Tooltip>
                <div style={{ position: "relative" }}>
                  <span
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#888",
                      pointerEvents: "none",
                    }}
                  >
                    {coupon?.type ? "%" : "$"}
                  </span>
                  <Input
                    type="number"
                    id="couponamount"
                    style={{
                      paddingLeft: "25px", // Add space for the prefix
                    }}
                    max={coupon?.type === true ? 100 : 100000}
                    placeholder={
                      coupon?.type ? "Enter Percentage" : "Enter Amount"
                    }
                    value={coupon?.amount || ""}
                    onChange={(e) => {
                      const value = parseInt(e.target.value) || "";
                      if (coupon?.type && (value < 0 || value > 100)) {
                        // Handle invalid input if needed
                      } else {
                        setCoupon((prev) => ({
                          ...prev,
                          amount: value,
                        }));
                      }
                    }}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <Label for="percenttrue">Visible in User Coupons</Label>

              <i
                className="mdi mdi-information-outline font-size-18"
                id="isPublic"
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => toggleTooltip("isPublic")}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.isPublic}
                target="isPublic"
                toggle={() => toggleTooltip("isPublic")}
              >
                Toggle to decide if the coupon should be visible to users in
                their account.
              </Tooltip>
              <FormGroup>
                <Switch
                  id="percenttrue"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      isPublic: !coupon?.isPublic,
                    }))
                  }
                  checked={coupon?.isPublic}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <Label for="usermail">Notify Users via Email</Label>

              <i
                className="mdi mdi-information-outline font-size-18"
                id="usersendMail"
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => toggleTooltip("usersendMail")}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.usersendMail}
                target="usersendMail"
                toggle={() => toggleTooltip("usersendMail")}
              >
                Send a notification email about the coupon to all users.
              </Tooltip>
              <FormGroup>
                <Switch
                  id="usermail"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      usersendMail: !coupon?.usersendMail,
                    }))
                  }
                  checked={coupon?.usersendMail}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="6">
              <Label for="shipment">Apply to Shipments</Label>

              <i
                className="mdi mdi-information-outline font-size-18"
                id="forShipment"
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => toggleTooltip("forShipment")}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.forShipment}
                target="forShipment"
                toggle={() => toggleTooltip("forShipment")}
              >
                Toggle to specify if the coupon is for shipment services. If set
                to "No," the coupon will be automatically created for
                marketplace purchases.
              </Tooltip>
              <FormGroup>
                <Switch
                  id="shipment"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      forShipment: !coupon?.forShipment,
                    }))
                  }
                  // onChange={() =>
                  //   setCoupon((prev) => ({
                  //     ...prev,
                  //     forShipment: !prev.forShipment,
                  //     forMarketplace: prev?.forShipment
                  //       ? prev?.forMarketplace
                  //       : false,
                  //   }))
                  // }
                  checked={coupon?.forShipment}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <Label for="restrictedUser">Restrict to Limited Users</Label>

              <i
                className="mdi mdi-information-outline font-size-18"
                id="isLimited"
                style={{ cursor: "pointer", marginLeft: "10px" }}
                onClick={() => toggleTooltip("isLimited")}
              ></i>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen.isLimited}
                target="isLimited"
                toggle={() => toggleTooltip("isLimited")}
              >
                Toggle to restrict coupon usage to a maximum number of users.
                Enter the limit value.
              </Tooltip>
              <FormGroup>
                <Switch
                  id="restrictedUser"
                  uncheckedIcon={<Offsymbol />}
                  className="me-1 mb-sm-8 mb-2 "
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    setCoupon((prev) => ({
                      ...prev,
                      isLimited: !coupon?.isLimited,
                    }))
                  }
                  checked={coupon?.isLimited}
                />
              </FormGroup>
            </Col>
          </Row>
          {coupon?.isLimited === true && (
            <Col md="12">
              <FormGroup>
                <Label for="maxuserlimit">Maximum User Value</Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="max_redemptions"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("max_redemptions")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.max_redemptions}
                  target="max_redemptions"
                  toggle={() => toggleTooltip("max_redemptions")}
                >
                  Calculate the percentage amount based on the total value.
                </Tooltip>
                <Input
                  type="string"
                  id="maxuserlimit"
                  placeholder="Enter User limit value"
                  value={coupon?.max_redemptions}
                  onChange={(e) =>
                    setCoupon((prev) => ({
                      ...prev,
                      max_redemptions:
                        parseInt(
                          e.target.value.toString()?.replace(/[^0-9.]/g, "")
                        ) || 0,
                    }))
                  }
                />
              </FormGroup>
            </Col>
          )}
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription1">
                  Coupon Description Point 1 (Required)
                </Label>

                <i
                  className="mdi mdi-information-outline font-size-18"
                  id="discription1"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => toggleTooltip("discription1")}
                ></i>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen.discription1}
                  target="discription1"
                  toggle={() => toggleTooltip("discription1")}
                >
                  A required line describing the coupon offer.
                </Tooltip>
                <Input
                  type="text"
                  id="discription1"
                  placeholder="please enter discription"
                  value={coupon?.discription1}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription1: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription2">
                  Coupon Description Point 2 (Optional)
                  <i
                    className="mdi mdi-information-outline font-size-18"
                    id="discription2"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => toggleTooltip("discription2")}
                  ></i>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen.discription2}
                    target="discription2"
                    toggle={() => toggleTooltip("discription2")}
                  >
                    An optional line with additional details about the coupon.
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  id="discription2"
                  placeholder="please enter discription"
                  value={coupon?.discription2}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription2: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription3">
                  Coupon Description Point 3 (Optional)
                  <i
                    className="mdi mdi-information-outline font-size-18"
                    id="discription3"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => toggleTooltip("discription3")}
                  ></i>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen.discription3}
                    target="discription3"
                    toggle={() => toggleTooltip("discription3")}
                  >
                    Another optional detail about the coupon, if needed.
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  id="discription3"
                  placeholder="please enter discription"
                  value={coupon?.discription3}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription3: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="my-2">
            <Col md="12">
              <FormGroup>
                <Label for="discription4">
                  Coupon Description Point 4 (Optional)
                  <i
                    className="mdi mdi-information-outline font-size-18"
                    id="discription4"
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => toggleTooltip("discription4")}
                  ></i>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen.discription4}
                    target="discription4"
                    toggle={() => toggleTooltip("discription4")}
                  >
                    A final optional detail about the coupon offer.
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  id="discription4"
                  placeholder="please enter discription"
                  value={coupon?.discription4}
                  onChange={(e) => {
                    setCoupon((prev) => ({
                      ...prev,
                      discription4: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px auto",
            }}
          >
            <Button
              size="md"
              disabled={loading}
              onClick={submitRequest}
              style={{
                backgroundColor: "#0077B6",
                color: "white",
              }}
            >
              {loading ? (
                <Spinner
                  style={{ width: "2rem", height: "2rem" }}
                  children={false}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default AdminCoupons;