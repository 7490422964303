import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import toastr from "toastr";


import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { del, get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";

const ShipmentDuration = () => {
  const navigation = useHistory();
  const [dataObject, setDataObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ShipmentCharges, setShipmentCharges] = useState([]);
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();

  const handleModalShow = () => {
    setModalShow((prev) => !prev);
  };
  function getTotalFieldValue(info) {
    const local_delivery_charge = info?.local_delivery_charge
      ? info?.local_delivery_charge
      : 0;
    const international_courier_charge =
    info?.international_courier_charge
        ? info?.international_courier_charge
        : 0;
    const human_resource_charge = info?.human_resource_charge
      ? info?.human_resource_charge
      : 0;
    const taxes = info?.taxes ? info?.taxes : 0;
    let grandTotal = local_delivery_charge + international_courier_charge;
    if (info?.Order_charges_type === "ECOM") {
      grandTotal += human_resource_charge;
    }
    const taxAmount = (grandTotal * taxes) / 100;
    return taxAmount+grandTotal;
  }
  function calculateShipmentAmountTotal(shipType,orderType,value=0){
    const charges = ShipmentCharges.find(el=>el.shipping_charges_type===shipType && el.Order_charges_type===orderType)
    const amountWithTax = getTotalFieldValue({...charges,Order_charges_type:orderType})
    let grandTotal = amountWithTax*value
    if(dataObject?.discount){
     const discountAmount = (grandTotal * dataObject?.discount)/100
     grandTotal = grandTotal - discountAmount
    }
    return grandTotal
  }
  
  async function handleNewDataObjectCreation() {
    try {
      setLoading(true);
      await post("/api/create-shipment-type-duration", dataObject);
      handleModalShow();
      getAllCategories();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      toastr.error(e?.response?.data?.message || "something went wrong")

    }
  }
  const handleObjectChange = (event) => {
    const { name, value } = event.target;
    const numericValue = parseFloat(value);
    if (value === "" || isNaN(numericValue) || numericValue < 0) {
      return; 
    }
    setDataObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    getAllCategories();
  }, []);
  async function getAllCategories() {
    try {
      const content = await post("/api/get-shipment-type-duration", {});
      setOrders(content.shipmentAndDuration);
    } catch (e) {
      console.log(e);
    }
  }
  async function deleteType(id) {
    try {
      await del(`/api/delete-shipment-type-duration/${id}`, {});
    } catch (e) {
      console.log(e);
    }
  }
 

  const { SearchBar } = Search;

  // Pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: orders.length,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  // const selectRow = {
  //   mode: "checkbox",
  // };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "downloaded_image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Shipment Duration"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Shipment Duration", link: "/shipment-duration" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={EcommerceOrderColumns(
                          setDataObject,
                          handleModalShow,
                          getAllCategories,
                          deleteType,
                          true
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    hidden={true}
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleModalShow();
                                      setDataObject({});
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Add New
                                    Shipment Duration
                                  </a>
                                </div>
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check custom-scroll-table"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={modalShow} toggle={handleModalShow}>
        <ModalHeader toggle={handleModalShow}>
          Shipment Duration Management
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="duration">
                  Duration
                </Label>
                {/* <Input
                  id="duration"
                  name="duration"
                  type="text"
                  value={dataObject?.duration}
                  onChange={handleObjectChange}
                  className="form-control"
                /> */}
                <Input
                  id="duration"
                  name="duration"
                  type="text"
                  value={dataObject?.duration}
                  onChange={handleObjectChange}
                  className="form-control"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[A-Za-z]/g, ""))
                  } // Prevents alphabets in real-time
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="type">
                  Transportation type
                </Label>
                <DropDownComponent
                  options={["AIR", "SHIP"]}
                  name={"Transportation type"}
                  updateName={"type"}
                  objectToBeUpdated={setDataObject}
                  defaultValue={dataObject?.type}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                ) : (
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    onClick={handleNewDataObjectCreation}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
function DropDownComponent({
  options = [],
  name,
  updateName,
  objectToBeUpdated,
  defaultValue = "",
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  function toggleDropdown() {
    setDropDownOpen((prev) => !prev);
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        {selectedValue || name}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((element, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelectedValue(element);
                objectToBeUpdated((prev) => ({
                  ...prev,
                  [updateName]: element,
                }));
              }}
            >
              {element}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}
export default ShipmentDuration;
