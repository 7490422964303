import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, size } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import * as moment from "moment";
import toastr from "toastr";
import Editor from "react-simple-wysiwyg";

import {
  Spinner,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
} from "reactstrap";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import EcommerceOrderColumns from "./EcommerceOrderColumns";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "../../helpers/api_helper";
import MasterForm from "../../components/VendorRegistration/MasterForm";
import ProductImages from "../../components/ProductImages";
import SupportTicketAttachments from "../../components/SupportTicketAttachments";
import { selectedTicketToViewChat } from "../../store/userAuthInfo/actions";

const AdminQueries = () => {
  const navigation = useHistory();
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [ticketObject, setTicketObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.userAuthInfo.user);
  const [tickets, setTickets] = useState([]);
  const pageOptions = {
    sizePerPage: 5,
    totalSize: tickets.length,
    custom: true,
  };
  const { SearchBar } = Search;
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  function showNewModal() {
    setShowModal((prev) => !prev);
    setSelectedFiles([])
    setTicketObject({})
  }
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles((prev) => [...prev, ...files]);
  };

  // const selectRow = {
  //   mode: "checkbox",
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setTicketObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  async function getAllUserTickets() {
    const ticketInfo = await post("/api/tickets/get-all-vendor-tickets", {});
    setTickets(ticketInfo);
  }

  async function createNewTicket() {
    try {
      setLoading(true);
      if(!ticketObject?.title){
        toastr.error("Please provide title")
        setLoading(false)

        return
      }
      if(!ticketObject?.description){
        toastr.error("Please provide description")
        setLoading(false)

        return
      }
      if(!ticketObject?.subject){
        toastr.error("Please provide subject")
        setLoading(false)

        return
      }
      if(!ticketObject?.ticket_type){
        toastr.error("Please select ticket type")
        setLoading(false)

        return
      }
      if(!ticketObject?.ticket_priority){
        toastr.error("Please select ticket priority")
        setLoading(false)

        return
      }

      const formData = new FormData();

      // Append multiple files to the FormData object
      for (let i = 0; i < selectedFiles.length; i++) {
        if (!selectedFiles[i]?.oldImage) {
          formData.append("attachment", selectedFiles[i]);
        }
      }

      formData.append(
        "objectDetails",
        JSON.stringify({
          title:ticketObject?.title,
          description:ticketObject?.description,
          ticket_priority:ticketObject?.ticket_priority,
          ticket_type:ticketObject?.ticket_type,
          subject:ticketObject?.subject,
          created_by_vendor_id:user?.id

        })
      );

      const config = {
        method: "POST",
        body: formData,
      };

      // Send the FormData to the server
      const responseObj = await fetch(
        `${process.env.REACT_APP_AUTHDOMAIN}/api/tickets/create-ticket-vendor`,
        config
      );
      const res = await responseObj.json();
      if(!res?.status){
        toastr.error(res.message)
        setLoading(false)
        return
      }
      toastr.success("Support ticket added!", "Success");
      getAllUserTickets()
      showNewModal()

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllUserTickets();
  }, [user]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Support Tickets"
            breadcrumbItems={[
              { title: "Dashboard", link: "/dashboard" },
              { title: "Support Tickets", link: "/#" },
            ]}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={tickets}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={tickets}
                        columns={
                          EcommerceOrderColumns(
                            dispatch,
                            navigation,
                            user

                          )
                          // setDataObject,
                          // handleModalShow,
                          // getAllCategories
                        }
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      on
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                {!user?.isAdmin?<div className="text-sm-end">
                                  <a
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showNewModal();
                                      
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-1" /> Create New Support Ticket
                                  </a>
                                </div>:null}
                              </Col>
                            </Row>
                            <div
                              className="table-responsive"
                              style={{ minHeight: "200px" }}
                            >
                              <BootstrapTable
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap table-check custom-scroll-table"
                                }
                                headerWrapperClasses={"table-light"}
                              />
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal size="xl" isOpen={showModal} toggle={showNewModal}>
        <ModalHeader toggle={showNewModal}>Create New Ticket</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="ticket_type">
                  Ticket Type
                </Label>
                <DropDownComponent
                  options={["SALES", "ACCOUNTS", "OTHERS"]}
                  name={"Ticket Type"}
                  updateName={"ticket_type"}
                  objectToBeUpdated={setTicketObject}
                  defaultValue={ticketObject?.ticket_type}
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="ticket_priority">
                  Ticket Priority
                </Label>
                <DropDownComponent
                  options={["LOW", "MEDIUM", "HIGH"]}
                  name={"Ticket Priority"}
                  updateName={"ticket_priority"}
                  objectToBeUpdated={setTicketObject}
                  defaultValue={ticketObject?.ticket_priority}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="title">
                  Title
                </Label>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  value={ticketObject?.title}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </Col>

            <Col>
              <div className="mb-3">
                <Label className="form-label" htmlFor="subject">
                  Subject
                </Label>
                <Input
                  id="subject"
                  name="subject"
                  type="text"
                  value={ticketObject?.subject}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <div className="mb-3">
        <label className="form-label" htmlFor="productdesc">
          Description
        </label>
        <Editor
          containerProps={{ style: { height: "200px" } }}
          value={ticketObject?.description}
          onChange={(e) => {
            setTicketObject((prev) => ({
              ...prev,
              description: e.target.value,
            }));
          }}
        />
      </div>
          <SupportTicketAttachments
            handleAcceptedFiles={handleAcceptedFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />

          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Spinner
                    style={{ width: "2rem", height: "2rem" }}
                    children={false}
                  />
                ) : (
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light"
                    type="submit"
                    onClick={createNewTicket}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

function DropDownComponent({
  options = [],
  name,
  updateName,
  objectToBeUpdated,
  defaultValue = "",
}) {
  const [dropdownOpen, setDropDownOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  function toggleDropdown() {
    setDropDownOpen((prev) => !prev);
  }
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="#000">
      <DropdownToggle className="bg-primary" caret>
        {selectedValue || name}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((element, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={() => {
                setSelectedValue(element);
                objectToBeUpdated((prev) => ({
                  ...prev,
                  [updateName]: element,
                }));
              }}
            >
              {element}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default AdminQueries;
